<template>

<div class="completed" v-if="tasks.length">

	<div class="completed-title">Completed tasks</div>

	<com-task :task="task" v-for="task in tasks" :key="task.id" />

</div>

</template>

<script>

import comTask from './Task'

export default {

	components: {
		'com-task': comTask
	},

	computed: {
		
		completed: function() {

			return this.$store.getters['session/tasks/completed']

		},

		tasks: function() {

			return this.$_.filter(this.$store.getters['category/tasks'], function(task) {

				return this.$_.contains(this.completed, task.id)

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.completed-title {
	color: #428103;
	font-size: 16px;
	font-weight: bold;
	padding: 32px 0px;
	text-transform: uppercase;
}

</style>
