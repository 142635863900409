import axios from 'axios'
import store from "./store"
import pubsub from './pubsub'

export default {
	
	request: function(url, params) {

		params = params || {}

		url = 'https://chisustober.recyclebycity.com/api/' + url + '.json'

		return new Promise(function(resolve, reject) {

			axios({
				url: url,
				synchronous: false,
				method: 'POST',
				headers: {
					'content-type': 'application/json',
					'x-requested-with': 'xmlhttprequest'
				},
				withCredentials: true,
				data: params,
				onUploadProgress: function(e) {

					pubsub.$emit('api.progress', Math.round((e.loaded * 100) / e.total))
					
				}.bind(this)
			}).then(function(response) {

				store.commit('time/offset', response.data.time - store.getters['time/raw'])

				if (response.status !== 200) {

					store.commit('time/offset', response.data.time - store.getters['time/raw'])

					reject(response.data.data)


				} else {

					resolve(response.data.data)

				}

			}, function(error) {

				reject(error)

			})

		}.bind(this))

	}

}