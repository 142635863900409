<template>

<app-screen :loading="true">

</app-screen>

</template>

<script>

import Cookies from 'js-cookie'

export default {

	created: function() {

		this.$api.request(this.$constants.api.logout).then(function() {

			Cookies.remove('key')

			window.location.reload()

		}.bind(this), function() {

			window.location.reload()

		}.bind(this))

	}

}

</script>

<style scoped>

</style>
