<template>

<div class="rules">

	<div class="rules-text" v-html="text"></div>

</div>

</template>

<script>

export default {

	computed: {

		text: function() {

			return this.texts.ob.rules.text.replace('{{username}}', this.$store.getters['session'].name.split(' ')[0])

		}

	}

}

</script>

<style scoped>

.rules {
	width: 100%;
	max-height: 100%;
}

.rules-text >>> h2 {
	color: #333;
	width: 100%;
	text-transform: uppercase;
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 20px;
}

.rules-text >>> p {
	color: #333;
	width: 100%;
	font-size: 16px;
	line-height: 22px;
	margin-bottom: 20px;
}

.rules-text >>> ul {
	list-style: disc;
	margin-bottom: 32px;
	list-style-position: outside;
}

.rules-text >>> ol {
	list-style: decimal;
	margin-bottom: 32px;
	list-style-position: outside;
}

.rules-text >>> li {
	color: #428103;
	width: 100%;
	font-size: 16px;
	line-height: 24px;
}

</style>
