<template>

<div id="app" :class="{'is-desktop': window.is.desktop, 'is-tablet': window.is.tablet, 'is-mobile': window.is.mobile}">

  <router-view></router-view>

</div>

</template>

<script>

export default {

	computed: {
	
		route: function() {
		
			return this.$route.path
		
		}
	
	},

	watch: {
	
		route: function() {
		
			// eslint-disable-next-line
			gtag('event', 'page_view', {
				page_location: window.location.href
			})
		
		}
	
	}

}

</script>

<style>

body {
	overflow: hidden;
	background-color: #666;
	min-height: calc(100vh - var(--vh-offset, 0px));
	user-select: none;
	touch-action: manipulation;
}

#app {
	position: fixed;
	overflow: hidden;
	background-color: #fff;
	opacity: 1;
	left: 0px;
	top: 0px;
	width: 100%;
	height: calc(100vh - var(--vh-offset, 0px));
	transition: all 300ms ease-in-out;
}

#app.is-desktop {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 380px;
	height: 670px;
}

</style>
