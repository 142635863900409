<template>

<input type="checkbox" class="check" v-on:change="onChange" />

</template>

<script>

export default {

	props: ['placeholder', 'tabindex', 'locked', 'value', 'email', 'validated', 'loading'],

	methods: {

		onChange: function() {

			this.$emit('change', !this.value)

		}

	}

}

</script>

<style scoped>

.check {
}

.check.is-active {
    background-color: #428103;
    border-color: #428103;
    color: #fff;
}

</style>