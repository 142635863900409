<template>

<app-screen title="Privacy" :back="(loggedin) ? false : 'Login'" :textOnly="true" :menu="loggedin" class="screen-rules">

	<div v-html="texts.privacy"></div>
	
</app-screen>

</template>

<script>

export default {

}

</script>

<style scoped>

.screen-rules {
	background-color: #fff!important;
}

</style>
