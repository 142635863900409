<template>

<app-screen :centralised="true">

	<div class="login-wrapper">

		<div class="login-rbc" />
		<div class="login-logo" />

		<div class="login-intro" v-if="!is.newbie">
			
			Sign in

		</div>

		<div class="login-intro" v-if="is.newbie">
			
			Register

		</div>

		<div class="login-email">

			<app-input ref="email" :locked="is.newbie" :tabindex="1" type="text" :email="true" :loading="is.checking" :validated="is.registered" placeholder="Email" v-model="input.email" />

		</div>

		<div class="login-register" v-if="is.newbie">

			<app-input :tabindex="2" type="text" placeholder="Username" v-model="input.name" />

		</div>

		<div class="login-register-agree" v-if="is.newbie">

			<app-input type="check" v-model="input.agree" />
		
			<div>I agree to the <router-link :to="{name: 'Terms'}">Terms</router-link> and <router-link :to="{name: 'Privacy'}">Privacy Policy</router-link></div>
			
		</div>

		<p class="login-register-legal" v-if="!is.newbie">Rest assured:<br/>Your email address will only be used for the purposes of this contest. We will not spam or sell your information.</p>

	</div>

	<app-button text="Continue" class="login-button" :errored="is.errored" v-on:click="onContinueClick" v-if="!is.newbie" :disabled="!isValidEmail" :loading="is.checking || is.registering" />
	<app-button text="Register" class="login-button" :errored="is.errored" v-on:click="onRegisterClick" v-if="is.newbie" :disabled="!isValidRegistration" :loading="is.checking || is.registering" />

</app-screen>

</template>

<script>

export default {

	data: function() {

		return {
			key: '',
			input: {
				email: '',
				name: '',
				agree: false
				//team: false
			},
			is: {
				registered: false,
				registering: false,
				checking: false,
				newbie: false,
				errored: false
			},
			re: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		}

	},

	computed: {

		teams: function() {

			var teams = []
			
			this.$_.each(this.$store.getters['teams'], function(team) {

				teams.push({
					value: team.id,
					text: team.name
				})

			})

			return teams

		},

		emailAddress: function() {

			return this.input.email

		},

		isValidEmail: function() {

			var email = this.input.email.toLowerCase()

			if (this.re.test(String(email).toLowerCase())) {

				var whitelist = this.$store.getters['settings'].whitelist

				if (whitelist) {

					return this.$_.find(whitelist, function(domain) {

						return email.indexOf(domain) > 0

					})

				} else {

					return true

				}

			} else {

				return false

			}

		},

		isRegistering: function() {

			return this.isValidEmail && !this.is.registered

		},

		isValidRegistration: function() {

			return this.input.name && this.input.agree //&& this.input.team

		},

		isValid: function() {

			return this.isValidEmail && this.isValidRegistration

		}

	},

	watch: {

		isValidEmail: function(n) {

			if (!n) {

				this.is.registered = false

			}

		}

	},

	methods: {

		onRegisterClick: function() {

			if (this.isRegistering) {

				this.is.registering = true
				this.is.errored = false

				this.$api.request(this.$constants.api.login.register, {
					email: this.input.email,
					name: this.input.name,
					//team: this.input.team
				}).then(function(response) {

					this.$router.push({
						name: 'Verify',
						query: {
							user: response.registered
						}
					})

				}.bind(this), function() {

					this.is.registering = false
					this.is.errored = true

				}.bind(this))

			} else {

				this.$router.push({
					name: 'Verify',
					query: {
						user: this.is.registered
					}
				})

			}

		},

		onContinueClick: function() {

			if (this.isValidEmail) {

				this.is.checking = true

				this.$api.request(this.$constants.api.login.check, {
					email: this.input.email
				}).then(function(response) {

					this.is.registered = response.registered
					this.is.checking = false

					if (this.is.registered) {

						this.$router.push({
							name: 'Verify',
							query: {
								user: this.is.registered
							}
						})

					} else {

						this.is.newbie = true

					}

				}.bind(this))

			}

		}

	}

}

</script>

<style scoped>

.login-rbc {
	position: absolute;
	top: -200px;
	left: calc(50% - 180px);
	width: 76px;
	height: 76px;
	background-image: url(~@/assets/login-rbc.png);
	background-size: 76px 76px;
	background-repeat: no-repeat;
}

.login-logo {
	position: absolute;
	top: -200px;
	left: 0px;
	height: 200px;
	width: 100%;
	background-image: url(~@/assets/login-logo.png);
	background-size: 142px 146.5px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

.login-logo:before {
	content: '';
	position: absolute;
	left: calc(50% + 80px);
	top: 15px;
	width: 36px;
	height: 38px;
	background-image: url(~@/assets/login-leaf.png);
	background-size: 36px 38px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

.login-logo:after {
	content: '';
	position: absolute;
	left: calc(50% - 160px);
	top: 95px;
	width: 31.5px;
	height: 32.5px;
	background-image: url(~@/assets/login-star.png);
	background-size: 31.5px 32.5px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

.login-wrapper {
	position: relative;
	background-color: #fff;
	border-radius: 20px;
	padding: 20px;
	margin-top: 50%;
	width: calc(100% - 20px);
	max-width: 320px;
	height: 270px;
}

.login-wrapper:before {
	content: '';
	position: absolute;
	left: calc(50% + 80px);
	top: -50px;
	width: 90.5px;
	height: 87px;
	background-image: url(~@/assets/login-thrift.png);
	background-size: 90.5px 87px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

.login-wrapper:after {
	content: '';
	position: absolute;
	left: calc(50% + 110px);
	top: -140px;
	width: 58.5px;
	height: 74.5px;
	background-image: url(~@/assets/login-bin.png);
	background-size: 58.5px 74.5px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

.login-intro {
	font-size: 20px;
	line-height: 24px;
	color: #428103;
	width: 100%;
	padding: 20px 0px 30px 0px;
	font-weight: 600;
	text-transform: uppercase;
	text-align: center;
}

.login-email {
	padding: 0px;
	width: 100%;
}

.login-button {
	width: 300px;
	margin-top: 20px;
}

.login-register {
	width: 100%;
}

.login-register >>> .input {
	margin: 8px 0px;
}

.login-register-agree {
	font-size: 12pt;
	line-height: 20px;
	color: #333;
	display: grid;
	align-items: center;
	grid-template-columns: 16px auto;
	grid-gap: 16px;
}

.login-register-agree a {
	color: #428103;
	font-weight: 500;
}

.login-register-legal {
	font-size: 10pt;
	line-height: 20px;
	color: #333;
	text-align: center;
	margin-top: 20px;
}

.login-register-legal a {
	color: #428103;
	font-weight: 500;
}

</style>
