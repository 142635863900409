<template>

<app-screen :title="texts.ob.rules.title" :menu="true" :textOnly="true" class="screen-rules">

	<div v-html="text"></div>
	
</app-screen>

</template>

<script>

export default {

	computed: {

		text: function() {

			return this.texts.ob.rules.text.replace('{{username}}', this.$store.getters['session'].name.split(' ')[0])

		}

	}

}

</script>

<style scoped>

.screen-rules {
	background-color: #fff!important;
}

</style>
