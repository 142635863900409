<template>

<div class="photo">

	<div class="photo-capture" v-if="is.capturing">

		<div class="photo-back" v-on:click="onBackClick"><i class="fa fa-chevron-left"></i></div>
		
		<com-webcam ref="webcam" :selectFirstDevice="true" class="photo-capture-preview" />

		<app-button text="Capture" class="photo-capture-button" v-on:click="onCaptureClick" />

	</div>

	<input ref="upload" type="file" accept=".jpeg,.jpg,.png" v-on:change="onSelectedFile" class="photo-select-upload" />

	<div class="photo-preview" :style="{backgroundImage: photoBackground}" :class="{'is-previewing': photo}">

		<i class="fa fa-camera" v-if="!photo"></i>

	</div>

	<div class="photo-options">

		<app-button text="Take photo" v-if="!photo && !window.is.mobile" :disabled="!device || saving" class="photo-options-item" v-on:click="onTakeClick" />
		<app-button text="Retake photo" v-if="photo && !window.is.mobile" :disabled="!device || saving" class="photo-options-item" v-on:click="onTakeClick" />
		<app-button text="Take photo" v-if="window.is.mobile" :loading="is.reading" :disabled="saving" class="photo-options-item" v-on:click="onSelectClick" />
		<app-button text="Select photo..." v-if="!window.is.mobile" :loading="is.reading" :disabled="saving" class="photo-options-item" v-on:click="onSelectClick" />

	</div>

</div>

</template>

<script>

import { WebCam } from "vue-web-cam"

export default {

	props: ['saved', 'saving'],

	components: {
		'com-webcam': WebCam
	},

	data: function() {

		return {
			device: false,
			photo: false,
			is: {
				capturing: false,
				selecting: false,
				reading: false
			}
		}

	},

	created: function() {

		this.device = true

		if (this.saved) {

			this.photo = this.saved.asset 

		}

	},

	computed: {

		photoBackground: function() {

			return (this.photo) ? 'url(' + this.photo + ')' : false

		}

	},

	methods: {

		onSelectedFile: function(e) {

			this.is.reading = true

			var file = e.target.files[0]

			var reader = new FileReader()

			reader.onloadend = function() {

				this.photo = reader.result 

				this.is.selecting = false
				this.is.reading = false

				this.$emit('selected', this.photo)

			}.bind(this)

			reader.readAsDataURL(file)

		},

		onBackClick: function() {

			this.is.capturing = false

		},

		onCaptureClick: function() {

			this.photo = this.$refs.webcam.capture()
			this.is.capturing = false

			this.$emit('selected', this.photo)

		},

		onTakeClick: function() {

			navigator.mediaDevices.getUserMedia({
					audio: true, 
					video: true
			}).then(function(stream) {

				stream.stop()

				this.is.capturing = true
				this.is.selecting = false
				
			}.bind(this), function() {

				alert('No webcam detected')

			})

		},

		onSelectClick: function() {

			this.is.capturing = false
			this.is.selecting = true

			this.$refs.upload.click()

		}

	}

}

</script>

<style scoped>

.photo-preview {
	background-color: #e4e4e4;
	width: 100%;
	height: 216px;
	border-radius: 9px;
	margin-bottom: 9px;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.photo-preview.is-previewing {
	background-color: #000;
}

.photo-preview .fa {
	color: #428103;
	font-size: 96px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.photo-options {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.photo-options-item {
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: 0;
	margin: 0px 2px;
}

.photo-capture {
	position: fixed;
	background-color: #000;
	z-index: 10;
	top: 0px;
	left: 0px;
	width: 100%;
	bottom: 0px;
	height: 100%;
}

.photo-capture-preview {
	position: absolute;
	left: 0px;
	height: 100%;
	width: 100%;
	top: 0px;
	z-index: 1;
}

.photo-capture-button {
	left: 10px;
	right: 10px;
	bottom: 10px;
	width: calc(100% - 20px);
	position: absolute;
	z-index: 2;
}

.photo-select-upload {
	display: none;
}

.photo-back {
	width: 48px;
	height: 48px;
	position: absolute;
	left: 0px;
	top: 0px;
	font-size: 16px;
	color: #fff;
	text-align: center;
	line-height: 48px;
	cursor: pointer;
	z-index: 2;
}

</style>
