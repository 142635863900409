<template>

<app-screen :loading="is.loading" :menu="true" title="Feed" toolbar="feed" :tabCurrent="tab" :tabs="tabs" v-on:tab="onTabChange">

	<div class="empty" v-if="!feed.length && tab === 'all'">No items yet, check back later.</div>
	<div class="empty" v-if="!feed.length && tab === 'self'">No items yet, complete some tasks!</div>

	<div class="feed-wrapper">

		<com-item :item="item" v-for="(item, key) in feed" :key="key" />
	
	</div>
	
</app-screen>

</template>

<script>

import comItem from './feed/Item'

export default {

	components: {
		'com-item': comItem
	},

	data: function() {

		return {
			feed: [],
			tab: 'all',
			tabs: {
				all: 'Everyone',
				self: 'You'
			},
			is: {
				loading: true
			}
		}

	},

	created: function() {

		this.refresh()

	},

	methods: {

		onTabChange: function(value) {

			this.tab = value

			this.refresh()

		},

		refresh: function() {

			this.is.loading = true

			this.$api.request(this.$constants.api.feed, {
				context: this.tab
			}).then(function(response) {

				this.feed = response.feed

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.feed-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.empty {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	line-height: 24px;
	font-size: 20px;
	font-weight: 500;
	color: #428103;
}

</style>
