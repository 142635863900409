<template>
	
<div class="category" v-on:click="onClick">

	<div class="category-icon" :style="{backgroundImage: 'url(' + item.icon + ')'}"></div>

	<div class="category-name">{{ item.name }}</div>
	
	<div class="category-points">Points: {{ scored }}/{{ total }}</div>

</div>

</template>

<script>

export default {

	props: ['item'],

	computed: {

		scored: function() {

			return this.$_.reduce(this.$store.getters['tasks'], function(total, task) {

				if (this.item.id === task.category) {

					var points = 0

					if(this.$_.contains(this.$store.getters['session/tasks/completed'], task.id)) {
					
						var saved = this.$store.getters['session/tasks'][task.id]

						if (task.type === this.$constants.task.multi && saved.status === this.$constants.task.incomplete) {

							points = Math.floor(saved.points / 2)

						} else {

							points = saved.points

						}
						
					}
					
					return total + points

				} else {

					return total

				}

			}.bind(this), 0)

		},

		total: function() {

			return this.$_.reduce(this.$store.getters['tasks'], function(total, task) {

				return (this.item.id === task.category) ? total + task.points : total

			}.bind(this), 0)

		}

	},

	methods: {

		onClick: function() {

			this.$store.commit('category', this.item.id)

			this.$router.push({
				name: 'Tasks'
			})

		}

	}
	
}

</script>

<style scoped>

.category {
	padding: 20px;
	border-radius: 20px;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	margin-bottom: 10px;
}

.category-icon {
	width: 40px;
	height: 40px;
	border-radius: 20px;
	background-color: #428103;
	background-position: 50% 50%;
	background-size: auto 24px;
	background-repeat: no-repeat;
	margin-bottom: 16px;
	color: #fff;
}

.category-name {
	font-weight: 600;
	margin-bottom: 20px;
	color: #212121;
	font-size: 16px;
	text-transform: uppercase;
}

.category-points {
	font-weight: 300;
	font-size: 16px;
	color: #212121;
	text-transform: uppercase;
}

</style>