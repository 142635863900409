<template>

<app-screen :centralised="true">

	<img class="countdown-logo" :src="texts.ob.intro.client" />

	<div class="countdown-text">

		<div class="countdown-text-prefix" v-html="texts.cd.prefix"></div>
		<div class="countdown-text-date">{{ date | formatDate(texts.cd.date) }}</div>
		<div class="countdown-text-text" v-html="texts.cd.text"></div>

	</div>
	
</app-screen>

</template>

<script>

export default {

	computed: {

		date: function() {

			return this.$store.getters['settings'].date.start

		}

	}

}

</script>

<style scoped>

.countdown-logo {
	width: auto;
	max-width: 360px;
	margin-top: 32px;
	height: auto;
}

.countdown-text-prefix {
	color: #428103;
	text-transform: uppercase;
	font-size: 16px;
	margin-bottom: 10px;
	text-align: center;
}

.countdown-text-date {
	color: #428103;
	font-size: 34px;
	text-align: center;
	font-weight: bold;
}

.countdown-text-text {
	font-size: 16px;
	line-height: 24px;
	margin-top: 32px;
	text-align: center;
	color: #428103;
}

</style>
