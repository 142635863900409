<template>

<app-screen :loading="true">

</app-screen>

</template>

<script>

import Cookies from 'js-cookie'
import axios from 'axios'

export default {

	created: function() {

		var key = Cookies.get('key')
		
			this.$api.request(this.$constants.api.session, {
				key: key
			}).then(function(response) {

				this.$store.commit('teams', response.teams)
				this.$store.commit('tasks', response.tasks)
				this.$store.commit('session', response.session)
				this.$store.commit('categories', response.categories)
				this.$store.commit('settings', response.settings)

				if (response.session) {

					axios.defaults.headers.common['Authorization'] = key

					if(Cookies.get('tasks') === undefined) {

						var tasks = []

						this.$_.each(this.$store.getters['tasks'], function(task) {
							
							tasks.push(task.id)
							
						})

						Cookies.set('tasks', JSON.stringify(tasks), {
							expires: 365
						})

					}

					this.$router.push({
						name: 'Categories'
					})

				} else {

					var bypass = window.location.href.indexOf('https://chisustober.recyclebycity.com') === -1

					if(this.$store.getters['settings'].date.start > 0 && this.$store.getters['settings'].date.start > this.$moment().utc().unix() && !bypass) {
	
						this.$router.push({
							name: 'Countdown'
						})

					} else if(
						this.$store.getters['settings'].date.end > 0 && this.$store.getters['settings'].date.end < this.$moment().utc().unix()
					) {
			
						this.$router.push({
							name: 'Locked'
						})
				
					} else {
			
						this.$router.push({
							name: 'Login'
						})
			
					}

				}

			}.bind(this), function() {

				this.$router.push({
					name: 'Login'
				})

			}.bind(this))
		
	}

}

</script>

<style scoped>

</style>
