<template>

<div class="input">
	
	<com-text :placeholder="placeholder" :loading="loading" :locked="locked" :validated="validated" :value="value" v-if="type === 'text'" v-on:change="onChange" />
	<com-select :placeholder="placeholder" :loading="loading" :options="options" :value="value" v-if="type === 'select'" v-on:change="onChange" />
	<com-check :placeholder="placeholder" :loading="loading" :value="value" v-if="type === 'check'" v-on:change="onChange" />
	
</div>

</template>

<script>

import comText from './input/Text'
import comSelect from './input/Select'
import comCheck from './input/Check'

export default {

	props: ['type', 'value', 'placeholder', 'validated', 'locked', 'loading', 'options'],

	components: {
		'com-text': comText,
		'com-select': comSelect,
		'com-check': comCheck
	},

	methods: {

		onChange: function(value) {

			this.$emit('input', value)

		}

	}

}

</script>

<style scoped>

.input {
	width: 100%;
}

</style>