<template>

<app-screen title="Welcome" class="screen-welcome">

	<com-rules v-if="current === 1" />

	<template v-slot:buttons>

		<app-button :text="texts.ob.rules.button" v-if="current === 1" v-on:click="onProceedClick" />

	</template>
	
</app-screen>

</template>

<script>

import comRules from './welcome/Rules'

export default {

	components: {
		'com-rules': comRules
	},

	data: function() {

		return {
			current: 1,
			is: {
				played: false
			}
		}

	},

	methods: {

		onProceedClick: function() {

			this.$api.request(this.$constants.api.tasks.start).then(function(response) {

				this.$store.commit('session', response.session)

			}.bind(this))

			this.$router.push({
				name: 'Categories'
			})

		}

	}

}

</script>

<style scoped>

.screen-welcome {
	background-color: #fff!important;
}

</style>
