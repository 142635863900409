<template>

<app-screen :menu="true" :loading="is.loading" title="Leaderboard" toolbar="leaderboard" :tabCurrent="tab" v-on:tab="onTabChange">

	<template v-if="tab === 'teams'">

		<div v-for="(team, index) in teams" :key="team.id" class="leaderboard-item" :class="{'is-own': team.id === ownTeam}">

			<div class="leaderboard-item-name">{{ team.name }}</div>
			<div class="leaderboard-item-score">Avg. score: {{ team.average | round }}</div>
			<div class="leaderboard-item-participation">Greenstars: {{ team.greenstars }}</div>
			<div class="leaderboard-item-position">{{ index + 1 | nth }}</div>

		</div>

	</template>

	<template v-if="tab === 'users'">

		<div class="empty" v-if="!users.length">No leaders yet, check back later.</div>

		<div v-if="self" :key="self.id" class="leaderboard-item is-self" :class="{'is-own': true}">

			<div class="leaderboard-item-name">{{ self.name }}</div>
			<div class="leaderboard-item-score">Score: {{ self.score }}</div>
			<div class="leaderboard-item-position">{{ self.rank | nth }}</div>

		</div>

		<div v-for="(user, index) in users" :key="user.id" class="leaderboard-item" :class="{'is-own': user.id === $store.getters['session'].id}">

			<div class="leaderboard-item-name">{{ user.name }}</div>
			<div class="leaderboard-item-score">Score: {{ user.score }}</div>
			<div class="leaderboard-item-position">{{ index + 1 | nth }}</div>

		</div>

	</template>
	
</app-screen>

</template>

<script>

import Vue from 'vue'

export default {

	data: function() {

		return {
			is: {
				loading: true
			},
			tab: 'users',
			tabs: {
				teams: 'Neighborhood',
				users: 'Individual'
			},
			self: {},
			users: [],
			teams: []
		}

	},

	created: function() {

		this.refresh()

	},

	computed: {

		ownTeam: function() {

			return this.$store.getters['session'].team

		}

	},

	methods: {

		onTabChange: function(value) {

			this.tab = value

			this.refresh()

		},

		refresh: function() {

			this.is.loading = true

			this.$api.request(this.$constants.api.leaderboard).then(function(response) {

				this.teams = response.teams
				this.users = response.users
				Vue.set(this, 'self', response.self)

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.leaderboard-text {
	color: #428103;
	font-size: 20px;
	line-height: 24px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1;
	margin-bottom: 32px;
}

.leaderboard-item {
	padding: 20px 64px 20px 20px;
	color: #428103;
	border-radius: 20px;
	background-color: #fff;
	margin-bottom:10px;
}

.leaderboard-item.is-self {
	margin-bottom: 40px;
}

.leaderboard-item.is-self:after {
	display: block;
	content: '';
	position: absolute;
	left: 0px;
	width: 100%;
	bottom: -20px;
	height: 1px;
	border-bottom: 2px dashed #428103;
}

.leaderboard-item.is-own {
	background-color: #428103;
	color: #fff;
}

.leaderboard-item-name {
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	text-transform: uppercase;
	margin-bottom: 20px;
}

.leaderboard-item-score {
	text-transform: uppercase;
	font-size: 16px;
	margin-bottom: 4px;
}

.leaderboard-item-participation {
	text-transform: uppercase;
	font-size: 16px;
}

.leaderboard-item-position {
	width: 40px;
	height: 40px;
	position: absolute;
	right: 20px;
	top: 50%;
	margin-top: -20px;
	border-radius: 20px;
	text-align: center;
	font-size: 16px;
	font-weight: bold;
	line-height: 40px;
	background-color: #428103;
	color: #fff;
}

.leaderboard-item.is-own .leaderboard-item-position {
	background-color: #fff;
	color: #428103;
}

.empty {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	line-height: 24px;
	font-size: 20px;
	font-weight: 500;
	color: #428103;
}

</style>
